var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserEcho" },
    _vm._l(_vm.userList, function (user) {
      return _c(
        "div",
        { key: user.staffId, staticClass: "user-box flex-between-center" },
        [
          _c(
            "div",
            { staticClass: "user-left flex-align-center" },
            [
              _c("CbAvater", {
                attrs: {
                  "avater-name": user.staffName,
                  "on-job-id": user.avater,
                  size: "30",
                },
              }),
            ],
            1
          ),
          _c("a-icon", {
            staticClass: "close",
            attrs: { type: "close" },
            on: {
              click: function ($event) {
                return _vm.delUser(user.staffId)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }